import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import WizardContext from 'libraries/wizard/wizard-context';
import { Investor, InvestorsApi, NetworthAnswerRequest, QuestionnaireQuestion } from 'api';
import {
  EducationOccupationFields,
  QuestionnaireAnswers,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import {
  convertEducationalQualificationFromApi,
  convertEducationalQualificationToApi,
  convertOccupationFromApi,
  extractNaturalAndLegalFromPerson,
} from 'core/api/conversions';
import useApiCall from 'hooks/use-api-call';
import { useServerConfigSelector } from 'core/config/hooks';
import {
  QUESTIONNAIRE_PAGES_SEGMENTS,
  QUESTIONNAIRE_PAGES_SEGMENTS_ECSP,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import { Money } from 'ui/types/money';
import { LosabilityQuestions } from 'libraries/wizard/components/loss-ability-calculator/hooks';
import { toMoney } from 'ui/helper/money';

export function useQuestionnaire() {
  const { nextStep, loading, resourceId: investorId } = useContext(WizardContext);
  const { loading: apiLoading, error, withApi, makeAuthenticatedApi } = useApiCall();
  const [editQuestions, setEditQuestions] = useState(false);
  const [prefilledQuestions, setPrefilledQuestions] = useState<QuestionnaireQuestion[] | null>(null);
  const [surveyAnswers, setSurveyAnswers] = useState<{ [key: string]: Object }>();
  const [initialEducationOccupation, setInitialEducationOccupation] = useState<EducationOccupationFields>();
  const [showLossabilityCalculator, setShowLossabilityCalculator] = useState(false);
  const [savedNetWorth, setSavedNetWorth] = useState<Money | null>(null);
  const [isSavingDisabled, setSavingDisabled] = useState(false);
  const [lossabilityAnswers, setLosabilityAnswers] = useState<NetworthAnswerRequest[]>([]);
  const [subStep, setSubStep] = useState(parseInt(localStorage.getItem(`questionnairePage.${investorId}`) || '1'));
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const { config } = useServerConfigSelector();
  const [investor, setInvestor] = useState<Investor | null>();
  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);
  const { naturalPerson } = extractNaturalAndLegalFromPerson(investor?.person);

  useEffect(() => {
    fetchInvestor();
  }, [investorId]);

  useEffect(() => {
    fetchPrefilledQuestionnaire();
  }, [investor?.id, JSON.stringify(investor?.surveyAnswers)]);

  const isEcsp = useMemo(() => {
    if (config.features.ecsp) return true;
    return false;
  }, [config.features.ecsp]);

  const questionnairePagesSegments = useMemo(() => {
    if (isEcsp) return QUESTIONNAIRE_PAGES_SEGMENTS_ECSP;
    return QUESTIONNAIRE_PAGES_SEGMENTS;
  }, [isEcsp]);

  const setQuestionnaireSubStep = (pageNumber: number, investor: string = investorId) => {
    setSubStep(pageNumber);
    localStorage.setItem(`questionnairePage.${investor}`, pageNumber.toString());
    if (pageNumber <= questionnairePagesSegments.length) {
      setShowLossabilityCalculator(false);
      setShowSummary(false);
    }
  };

  const onGoBackQuestionnaire = () => {
    setQuestionnaireSubStep(subStep - 1);
    setEditQuestions(true);
    //TO DO: discard values on the page which was not saved when clicking on the back button
  };

  const fetchInvestor = useCallback(() => {
    if (!investorId) return;

    withApi(async () => {
      const investor = await investorsApi.investorsRetrieve({ id: investorId });
      setInvestor(investor);

      const { naturalPerson } = extractNaturalAndLegalFromPerson(investor?.person);
      if (naturalPerson) {
        setInitialEducationOccupation({
          educationalQualification:
            naturalPerson.educationalQualification &&
            convertEducationalQualificationFromApi(naturalPerson.educationalQualification),
          occupation: naturalPerson.occupation ? convertOccupationFromApi(naturalPerson.occupation) : undefined,
        });
        setSurveyAnswers(investor?.surveyAnswers);
      }
    });
  }, [investorId]);

  const fetchPrefilledQuestionnaire = useCallback(async () => {
    if (!investorId) return;

    await withApi(async () => {
      const questions = await investorsApi.investorsPrefilledQuestionnaireList({
        id: investorId,
      });

      setPrefilledQuestions(questions);
    });
  }, [investorId]);

  const onQuestionnaireSaved = useCallback(async () => {
    if (isEcsp && investor?.id) {
      try {
        const answers = await investorsApi.investorsNetworthAnswersRetrieve({ id: investor.id });
        setShowLossabilityCalculator(true);
        setSavedNetWorth(answers.networth);
        setEditQuestions(false);
      } catch (e) {
        console.error(e);
        setShowLossabilityCalculator(true);
        setSavedNetWorth(null);
      }
    } else {
      setEditQuestions(false);
      onFinalize();
    }
  }, [config.features.ecsp, investor?.id]);

  const onSubmitAnswers = useCallback(
    async (
      values: { answers: QuestionnaireAnswers[]; educationOccupation: EducationOccupationFields },
      shouldFinalize: boolean = false,
    ) => {
      const answers =
        values.answers.length === 0
          ? []
          : values.answers.map((answer) => {
              return {
                id: answer.id,
                selectedOption: answer.selectedOption,
              };
            });

      await withApi(async () => {
        if (!investor?.id) return;
        await investorsApi.investorsPartialUpdate({
          id: investor.id,
          patchedInvestorUpdateRequest: {
            naturalPerson: {
              educationalQualification:
                values.educationOccupation.educationalQualification &&
                convertEducationalQualificationToApi(values.educationOccupation.educationalQualification),
              occupation: values.educationOccupation.occupation,
            },
          },
        });

        await investorsApi.investorsSurveyCreate({
          id: investor.id,
          updateSurveyAnswersRequest: { surveyAnswers: answers },
        });

        fetchInvestor();
        setEditQuestions(true);

        if (shouldFinalize) {
          if (isEcsp) {
            setQuestionnaireSubStep(questionnairePagesSegments.length + 1);
          } else {
            setQuestionnaireSubStep(1);
          }
          onQuestionnaireSaved();
        }
      });
    },
    [withApi, onQuestionnaireSaved, investorsApi, investor?.id, JSON.stringify(surveyAnswers)],
  );

  const onEditQuestions = () => {
    setEditQuestions(true);
  };

  const onFinalize = useCallback(() => {
    // jump to first possible step
    setQuestionnaireSubStep(1);
    nextStep();
  }, [nextStep]);

  //networth calculator functions
  const onSaveLossabilityEntries = useCallback(async () => {
    if (!investor?.id) return;

    setSavingDisabled(true);

    try {
      const answers = await investorsApi.investorsNetworthAnswersCreate({
        id: investor.id,
        investorNetworthRequest: {
          networthAnswers: lossabilityAnswers,
        },
      });
      setSavedNetWorth(answers.networth);
      setSavingDisabled(false);
      showSummaryPage();
      setShowLossabilityCalculator(false);
    } catch (e) {
      console.error(e);
      setSavingDisabled(false);
    }
  }, [lossabilityAnswers, investor?.id]);

  const onContinueWithoutSavingNetworth = () => {
    setConfirmModalOpen(false);
    setShowLossabilityCalculator(false);
    setShowSummary(true);
    setSavedNetWorth(null);
  };

  const onSkipNetworth = () => {
    setShowLossabilityCalculator(false);
    showSummaryPage();
  };

  const restartNetworthCalculation = useCallback(async () => {
    if (!investor?.id) return;

    const answers: NetworthAnswerRequest[] = Object.values(LosabilityQuestions).map((question) => ({
      questionId: question,
      answer: toMoney(0),
    }));

    try {
      await investorsApi.investorsNetworthAnswersCreate({
        id: investor.id,
        investorNetworthRequest: {
          networthAnswers: answers,
        },
      });
    } catch (e) {
      console.error(e);
    }

    setSavedNetWorth(null);
  }, [investorsApi, investor?.id]);

  // summary functions
  const showSummaryPage = () => {
    setShowSummary(true);
  };

  const onContinueSummary = () => {
    setShowSummary(false);
    onFinalize();
  };

  const onGoBackSummary = () => {
    setShowSummary(false);
    setShowLossabilityCalculator(true);
  };

  return {
    loading: loading || apiLoading,
    onEditQuestions,
    prefilledQuestions,
    surveyAnswers,
    investor,
    naturalPerson,
    error,
    onSubmitAnswers,
    onFinalize,
    initialEducationOccupation,
    editQuestions,
    setEditQuestions,
    onSaveLossabilityEntries,
    showLossabilityCalculator,
    setShowLossabilityCalculator,
    savedNetWorth,
    setSavedNetWorth,
    isSavingDisabled,
    setLosabilityAnswers,
    questionnairePagesSegments,
    questionnairePage: subStep,
    setQuestionnairePage: setQuestionnaireSubStep,
    isEcsp,
    onGoBackQuestionnaire,
    onContinueWithoutSavingNetworth,
    onSkipNetworth,
    isConfirmModalOpen,
    setConfirmModalOpen,
    showSummary,
    onContinueSummary,
    onGoBackSummary,
    restartNetworthCalculation,
    showWaiver: false,
    noAnswers: false,
  };
}
