import { DistributionPlatform, DistributionPlatformPermissionEnum } from 'src/api';

export const userHasPermission = (
  distributionPlatforms: DistributionPlatform[] | null,
  permission: DistributionPlatformPermissionEnum,
) =>
  !!distributionPlatforms &&
  distributionPlatforms.some(
    (distributionPlatform) => !!distributionPlatform?.permissions.find((perm) => perm === permission),
  );

export const hasManageIdentificationDataPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.ManageIdentificationData);
