import { InvestmentTokenPermissionEnum } from 'api';
import type { InvestmentToken, InvestmentTokenForInvestor } from 'api';

type permissionsTokenType = InvestmentTokenForInvestor | InvestmentToken | null | undefined;
type permissionsTokenArrayType = InvestmentTokenForInvestor[] | InvestmentToken[] | null | undefined;

export const userHasPermission = (tokens: permissionsTokenArrayType, permission: InvestmentTokenPermissionEnum) =>
  !!tokens && tokens.some((token) => !!token?.permissions.find((perm) => perm === permission));

export const userHasPermissionForAllProducts = (
  tokens: permissionsTokenArrayType,
  permission: InvestmentTokenPermissionEnum,
) => !!tokens && tokens.every((token) => !!token?.permissions.find((perm) => perm === permission));

export const currentTokenHasPermission = (token: permissionsTokenType, permission: InvestmentTokenPermissionEnum) =>
  !!token?.permissions.find((perm) => perm === permission);

export const hasViewPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.ViewData);

export const hasManageCreditDigitalSecuritiesPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.ManageCreditDigitalSecurities);

export const hasManageDataPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.ManageData);

export const hasManageDistributionPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.ManageDistribution);

export const hasManageIncomingPaymentsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.ManageIncomingPayments);

export const hasManageTriggerSolutionPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.TriggerSolution);

export const hasManageApprovalsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.ManageApprovals);

export const hasDownloadStatisticsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.DownloadStatistics);

export const hasManageRedemptionsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.ManageRedemptions);

export const hasViewPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.ViewData);

export const hasManageCreditDigitalSecuritiesPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.ManageCreditDigitalSecurities);

export const hasManageDataPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.ManageData);

export const hasManageDistributionPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.ManageDistribution);

export const hasManageIncomingPaymentsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.ManageIncomingPayments);

export const hasManageApprovalsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.ManageApprovals);

export const hasDownloadStatisticsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.DownloadStatistics);

export const hasManageRedemptionsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.ManageRedemptions);

export const hasManageVouchersPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.ManageVouchers);

export const currentTokenHasViewPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.ViewData);

export const currentTokenHasManageCreditDigitalSecuritiesPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.ManageCreditDigitalSecurities);

export const currentTokenHasManageDataPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.ManageData);

export const currentTokenHasManageDistributionPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.ManageDistribution);

export const currentTokenHasManageIncomingPaymentsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.ManageIncomingPayments);

export const currentTokenHasManageApprovalsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.ManageApprovals);

export const currentTokenHasDownloadStatisticsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.DownloadStatistics);

export const currentTokenHasManageRedemptionsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.ManageRedemptions);

export const currentTokenHasManageVouchersPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.ManageVouchers);
