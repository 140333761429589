import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import castArray from 'lodash/castArray';
import filter from 'lodash/filter';
import find from 'lodash/find';
import first from 'lodash/first';
import { InvestorStepEnum, InvestorsApi, StepEnum } from 'api';
import { useDispatch } from 'store/hooks';
import { WizardLayoutContext } from 'core/layout/wizard';
import WithConditionalPage from 'core/auth/components/with-conditional-page';
import useRegister from 'subapps/investment/pages/register/hooks/use-register';
import { AppType } from 'core/auth/types';
import sidebarMapping from 'subapps/investment/pages/register/helpers/sidebar-mapping';
import useApiCall from 'hooks/use-api-call';
import makeSidebarSteps from 'libraries/wizard/helpers/make-sidebar-steps';
import { setAuthToken } from 'core/auth/actions';
import { useCurrentUserSelector } from 'core/auth/hooks';
import getNextWithOverlap from 'helper/get-next-with-overlap';
import Translate from 'ui/atoms/translate';
import stepMapping from './steps';
import WizardContext from 'libraries/wizard/wizard-context';
import WizardContent from 'libraries/wizard/wizard-content';
import SideContent from 'subapps/investment/pages/register/side-content';
import { registerAccessCondition } from 'subapps/investment/pages/register/helpers/register-access-condition';
import useInvestmentOverview from './use-investment-overview';
import { useServerConfigSelector } from 'src/core/config/hooks';
import { useHistory } from 'react-router';
import { useQuestionnaire } from './steps/questionnaire/use-questionnaire';

export const Register: FunctionComponent = () => {
  const dispatch = useDispatch();

  const { setWizardSteps } = useContext(WizardLayoutContext);

  const { currentUser } = useCurrentUserSelector();
  const investorId = currentUser?.investor;

  const { step, steps, setStep, setSteps, loading, reload } = useRegister();

  const { makeAuthenticatedApi } = useApiCall(true);

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const { onCloseProcessOverviewOverlay, showProcessOverview } = useInvestmentOverview();

  const history = useHistory();

  const { setQuestionnairePage } = useQuestionnaire();

  const {
    config: { registerOnlyFlow },
  } = useServerConfigSelector();

  // handle sidebar step changes
  useEffect(() => {
    if (step) {
      setWizardSteps(makeSidebarSteps(step, steps, setStep, sidebarMapping(registerOnlyFlow.walletStepEnabled)));
    }
  }, [setWizardSteps, step, steps, setStep]);

  useEffect(() => {
    if (!step) return;

    const searchParams = new URLSearchParams(window.location.search);
    const currentStep = searchParams.get('page');

    if (currentStep === step) return;

    searchParams.set('page', step);
    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  }, [step]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        const searchParams = new URLSearchParams(location.search);
        const queryStep = searchParams.get('page');

        if (queryStep) {
          if (queryStep === StepEnum.QUESTIONNAIRE) {
            setQuestionnairePage(1, investorId);
          }
          setStep(queryStep as InvestorStepEnum);
        }
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  const changeStep = useCallback(
    async (step: InvestorStepEnum | InvestorStepEnum[] | undefined) => {
      if (!step) setStep(undefined);
      if (!investorId) return;

      const wizard = await investorsApi.investorsWizardRetrieve({ id: investorId });
      setSteps(wizard.steps);
      const stepsToChange = castArray(step);
      setStep(first(stepsToChange));
    },
    [setStep],
  );

  const finalize = useCallback(
    (accessToken?: string) => {
      if (accessToken) {
        dispatch(setAuthToken(accessToken));
      } else reload();
    },
    [dispatch, reload],
  );

  const nextStep = useCallback(() => {
    (async () => {
      if (!investorId) return;

      const { steps: newSteps } = await investorsApi.investorsWizardRetrieve({ id: investorId });
      if (steps && newSteps) {
        const nextStep = getNextWithOverlap(
          step,
          steps.map(({ step }) => step),
          filter(newSteps, { revisitable: true }).map(({ step }) => step),
        );

        if (nextStep) setStep(nextStep);
        setSteps(newSteps);
      }
    })();
  }, [step, steps, investorsApi, setStep, setSteps]);

  const lastRevisitableStep = useCallback(() => {
    if (steps) {
      const revisitableSteps = steps.filter((step) => !!step.revisitable);
      const currentStepIndex = revisitableSteps.findIndex((stepObj) => stepObj.step === step);
      const previousStep = revisitableSteps.slice(0, currentStepIndex).pop()?.step;

      return previousStep;
    }
    return undefined;
  }, [step, steps]);

  // get component and title of current step
  const stepComponent = useMemo(() => stepMapping(step), [step]);

  if (!stepComponent) return null;

  let { Component, DoneComponent } = stepComponent;

  const isCurrentStepDone = !!find(steps, { step, done: true });
  const isCurrentStepRevisitable = !!find(steps, { step, revisitable: true });

  const DefaultPageTitle = () => <Translate name="register.title" />;

  return (
    <WizardContext.Provider
      value={{
        loading,
        finalize,
        nextStep,
        lastRevisitableStep,
        DefaultPageTitle,
        isStepDone: isCurrentStepDone,
        isStepRevisitable: isCurrentStepRevisitable,
        changeStep: changeStep,
        resourceId: investorId || '',
        investmentId: '',
        isTransferInvitation: false,
        isRegisterOnlyFlow: true,
        onCloseProcessOverviewOverlay,
        showProcessOverview,
      }}
    >
      <WizardContent sideContent={step !== InvestorStepEnum.REGISTRATIONDONE && <SideContent />}>
        {(isCurrentStepDone && DoneComponent && <DoneComponent />) || <Component />}
      </WizardContent>
    </WizardContext.Provider>
  );
};

export default WithConditionalPage(Register, registerAccessCondition, AppType.INVESTMENT);
