import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'store/hooks';
import useGoTo from 'hooks/use-go-to';
import { useIsLoggedInSelector, useLogout } from 'core/auth/hooks';
import { setUserLanguage } from 'core/auth/actions';
import { I18NContext } from 'ui/i18n/provider';
import useApiCall from 'hooks/use-api-call';
import { UsersApi } from 'api';
import { ProgressStep } from 'core/layout/wizard/progress-steps';
import useIsMedia, { DEVICES } from 'src/ui/hooks/use-is-media';
import WizardNavBarDesktop from './wizard-navbar-desktop';
import WizardNavBarMobile from './wizard-navbar-mobile';

const WizardNavBar: FunctionComponent<{
  showInvestorOverview?: boolean;
  progressSteps: ProgressStep[];
  registerOnlyFlow?: boolean;
}> = ({ showInvestorOverview, progressSteps, registerOnlyFlow }) => {
  const { changeLocale, activeLocale } = useContext(I18NContext);

  const [isUserConfirmed, setUserConfirmed] = useState(false);

  const dispatch = useDispatch();
  const goToHome = useGoTo({ path: '/' });
  const isLoggedIn = useIsLoggedInSelector();
  const logout = useLogout();

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

  useEffect(() => {
    withApi(async () => {
      if (isLoggedIn) {
        const { confirmed } = await usersApi.usersMeRetrieve();
        setUserConfirmed(confirmed);
      }
    });
  }, [usersApi, withApi, isLoggedIn]);

  const switchLocale = useCallback(
    (locale: string) => {
      changeLocale(locale, { selection: true, user: isLoggedIn });
      if (isLoggedIn) {
        // TODO(niklasb) probably not the best place to do this
        dispatch(setUserLanguage(locale));
      }
    },
    [changeLocale, isLoggedIn, dispatch],
  );

  const onLogout = useCallback(() => {
    logout();
    goToHome();
  }, [logout, goToHome]);

  const isPhoneTablet = useIsMedia([DEVICES.phone, DEVICES.tablet]);

  return isPhoneTablet ? (
    <WizardNavBarMobile
      isUserConfirmed={isUserConfirmed}
      switchLocale={switchLocale}
      onLogout={onLogout}
      showInvestorOverview={showInvestorOverview}
      progressSteps={progressSteps}
      registerOnlyFlow={registerOnlyFlow}
      isLoggedIn={isLoggedIn}
      activeLocale={activeLocale}
    />
  ) : (
    <WizardNavBarDesktop
      isUserConfirmed={isUserConfirmed}
      switchLocale={switchLocale}
      onLogout={onLogout}
      showInvestorOverview={showInvestorOverview}
      progressSteps={progressSteps}
      registerOnlyFlow={registerOnlyFlow}
      isLoggedIn={isLoggedIn}
      activeLocale={activeLocale}
    />
  );
};

export default WizardNavBar;
